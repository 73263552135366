import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Plant } from "src/app/domain/models/plant";
import { IPlantRepository } from "src/app/domain/repositories/plant-repository.interface";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class PlantRepository implements IPlantRepository {
    constructor(private http: HttpClient) { }

    private buildUrl(plantId?: string): string {
        if (plantId)
            return `${environment.api}/plants/?plantId=${plantId}`
        return `${environment.api}/plants`
    }

    private buildStorageUrl(): string {
        return `${environment.apiUpload}/files`
    }

    getAll(): Observable<Plant[]> {
        return this.http.get<Plant[]>(this.buildUrl());
    }

    get(plantId: string): Observable<Plant[]> {
        return this.http.get<Plant[]>(this.buildUrl(plantId));
    }

    getImages(fileInfo: any): Observable<any> {
        const encodedFileInfo = JSON.stringify(fileInfo);
        const params = new HttpParams().set('fileInfo', encodedFileInfo);
        return this.http.get<any>(this.buildStorageUrl(), { params });
    }

    getImagesOptimized(fileInfo: any, size: string): Observable<any> {
        const encodedFileInfo = JSON.stringify(fileInfo);
        const params = new HttpParams().set('fileInfo', encodedFileInfo).set('size', size);
        return this.http.get<any>(this.buildStorageUrl(), { params });
    }

    deleteImage(fileId: string, fileType: string): Observable<boolean> {
        const options = {
            headers: {
                'Content-Type': 'application/json'
            },
            body: {
                fileId: fileId,
                fileType: fileType
            }
        };
        return this.http.delete<boolean>(`${this.buildStorageUrl()}`, options);
    }
}