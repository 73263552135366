import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

    constructor(private primengConfig: PrimeNGConfig, private translateService: TranslateService) {
        this.translateService.setDefaultLang('es-ES');
    }

    ngOnInit(): void {
        this.primengConfig.ripple = true;
        const savedLanguage = localStorage.getItem('selectedLanguage') || 'en';
        this.translateService.use(savedLanguage);
    }

    translate(lang: string) {
        this.translateService.use(lang);
        this.translateService.get('primeng').subscribe(res => this.primengConfig.setTranslation(res));
    }
}

export function createTranslateLoader(http: HttpClient) {
    const version = environment.appVersion;
    return new TranslateHttpLoader(http, './assets/i18n/', `.json?v=${version}`);
}