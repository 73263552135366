import { IJointRepository } from "src/app/domain/repositories/joint-respository.interface";
import { IJointService } from "./joint.service.interface";
import { Joint } from "src/app/domain/models/joint";
import { catchError, map, Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { JointChange } from "src/app/domain/models/joint-change";
import { environment } from "src/environments/environment";
import { IImageRepository } from "src/app/domain/repositories/image-repository.interface";

@Injectable({
    providedIn: 'root'
})
export class JointService implements IJointService {

    constructor(private jointRepository: IJointRepository,
        private imageRepository: IImageRepository
    ) { }
    
    getAllJoints(): Observable<Joint[]> {
        return this.jointRepository.getAll();
    }
    
    getJointDetails(jointId: string): Observable<Joint> {
        return this.jointRepository.get(jointId);
    }

    getJointChanges(jointId: string): Observable<JointChange[]> {
        return this.jointRepository.getChanges(jointId);
    }
    
    getJointImages(joint: Joint): Observable<string[]> {
        const fileInfo = this.createFileInfo(joint, `${joint.plant?.code}_IMAGE`);
        return this.jointRepository.getJointImages(fileInfo);
    }

    getJointDrawings(joint: Joint): Observable<string[]> {
        const fileInfoDrawing = this.createFileInfo(joint, joint.plant?.code!);
        return this.imageRepository.getImagesOptimized(fileInfoDrawing, 'extra').pipe(
            map(response => response.fileDownloadUrls.filter((url: string) => url.includes('.webp'))),
            catchError(error => {
            console.error('Error fetching plant drawings', error);
            return [];
            })
        );
    }

    deleteJointImage(image: string): Observable<boolean> {
        const [baseUrl] = image.split('?');
        let fileId = new URL(baseUrl).pathname.replace("_extra", "");
        return this.imageRepository.deleteImage(fileId, 'Image');
    }

    deleteJointDrawing(drawing: string): Observable<boolean> {
        const [baseUrl] = drawing.split('?');
        let fileId = new URL(baseUrl).pathname.replace("_extra", "");
        return this.imageRepository.deleteImage(fileId, 'Drawing');
    }

    private createFileInfo(joint: Joint, entityId: string): any {
        return {
            fileType: 'Image',
            businessVertical: environment.verticalBusiness,
            customerId: joint.company?.code ?? '',
            entityName: 'joints',
            entityId: entityId
        };
    }
}