import { IPlantRepository } from "src/app/domain/repositories/plant-repository.interface";
import { IPlantService } from "./plant.service.interface";
import { catchError, forkJoin, map, Observable } from "rxjs";
import { Plant } from "src/app/domain/models/plant";
import { environment } from "src/environments/environment";
import { Joint } from "src/app/domain/models/joint";
import { IJointRepository } from "src/app/domain/repositories/joint-respository.interface";
import { Injectable } from "@angular/core";
import { IImageRepository } from "src/app/domain/repositories/image-repository.interface";

@Injectable({
  providedIn: 'root'
})
export class PlantService implements IPlantService {

    constructor(private plantRepository: IPlantRepository, 
      private jointRepository: IJointRepository,
      private imageRepository: IImageRepository) { }
    

    getAllPlants(): Observable<Plant[]> {
        return this.plantRepository.getAll();
    }

    getPlantDetails(plantId: string): Observable<Plant | undefined> {
        return this.plantRepository.get(plantId).pipe(
          map(plants => {
            const plant = plants.find((p: Plant) => p.plantId === plantId);
            return plant;
          }),
          catchError(error => {
            console.error('Error fetching plant details', error);
            throw error;
          })
        );
    }

    getPlantImage(plant: Plant): Observable<string> {
        const fileInfo = this.createFileInfo(plant, `${plant.code}_IMAGE`);
        return this.imageRepository.getImagesOptimized(fileInfo, 'extra').pipe(
          map(response => response.fileDownloadUrls[0]),
          catchError(error => {
            console.error('Error fetching plant image', error);
            return [];
          })
        );
      }
    
    getPlantDrawings(plant: Plant): Observable<string[]> {
        const fileInfoDrawing = this.createFileInfo(plant, plant.code!);
        return this.imageRepository.getImagesOptimized(fileInfoDrawing, 'extra').pipe(
            map(response => response.fileDownloadUrls.filter((url: string) => url.includes('.webp'))),
            catchError(error => {
            console.error('Error fetching plant drawings', error);
            return [];
            })
        );
    }

    getPlantJoints(plantId: string): Observable<Joint[]> {
        return this.jointRepository.getJointsByPlantId(plantId);
    }

    deletePlantImage(image: string): Observable<boolean> {
        const [baseUrl] = image.split('?');
        let fileId = new URL(baseUrl).pathname.replace("_extra", "");
        return this.imageRepository.deleteImage(fileId, 'Image');
    }

    deletePlantDrawing(drawing: string): Observable<boolean> {
        const [baseUrl] = drawing.split('?');
        let fileId = new URL(baseUrl).pathname.replace("_extra", "");
        return this.imageRepository.deleteImage(fileId, 'Drawing');
    }

    private createFileInfo(plant: Plant, entityId: string): any {
        return {
            fileType: 'Image',
            businessVertical: environment.verticalBusiness,
            customerId: plant.company?.code ?? '',
            entityName: 'plants',
            entityId: entityId
        };
    }
}