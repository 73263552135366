import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Project } from "src/app/domain/models/project";
import { IProjectRepository } from "src/app/domain/repositories/project-repository.interface";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class ProjectRepository implements IProjectRepository {
    constructor(private http: HttpClient) { }

    private buildUrl(projectId?: string): string {
        if (projectId)
            return `${environment.api}/projects/?projectId=${projectId}`
        return `${environment.api}/projects`
    }

    getAll(): Observable<Project[]> {
        return this.http.get<Project[]>(this.buildUrl());
    }

    get(projectId: string): Observable<Project[]> {
        return this.http.get<Project[]>(this.buildUrl(projectId));
    }
}