import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent {
  
  availableLanguages = [
    { code: 'es-ES', label: 'Español' },
    { code: 'en', label: 'English' },
    { code: 'pt-BR', label: 'Portugues' },
  ];
  
  selectedLanguage: string | undefined;

  constructor(private translateService: TranslateService) {}

  ngOnInit() {
    this.selectedLanguage = localStorage.getItem('selectedLanguage') || 'en';
    this.translateService.use(this.selectedLanguage);
  }

  changeLanguage(event: Event) {
    const target = event.target as HTMLSelectElement;
    const language = target?.value || 'en';
    this.translateService.use(language);
    localStorage.setItem('selectedLanguage', language);
  }
}
