import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Joint } from "src/app/domain/models/joint";
import { JointChange } from "src/app/domain/models/joint-change";
import { IJointRepository } from "src/app/domain/repositories/joint-respository.interface";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class JointRepository implements IJointRepository {

    private buildUrl(jointId?: string): string {
        if (jointId)
            return `${environment.api}/joints/${jointId}`
        return `${environment.api}/joints`
    }

    private buildStorageUrl(): string {
        return `${environment.apiUpload}/files`
    }

    constructor(private http: HttpClient) { }

    getAll(): Observable<Joint[]> {
        return this.http.get<Joint[]>(this.buildUrl() + '?top=50&includeJointChanges=true');
    }

    getJointsByPlantId(plantId: string): Observable<Joint[]> {
        return this.http.get<Joint[]>(this.buildUrl() + '?plantId=' + plantId);
    }

    getJointsByProjectId(projectId: string): Observable<Joint[]> {
        return this.http.get<Joint[]>(this.buildUrl() + '?projectId=' + projectId);
    }

    get(jointId: string): Observable<Joint> {
        return this.http.get<Joint>(this.buildUrl(jointId) + '/details');
    }

    getChanges(jointId: string): Observable<JointChange[]> {
        return this.http.get<JointChange[]>(this.buildUrl(jointId) + '/changes');
    }

    getJointImages(fileInfo: any): Observable<any> {
        const encodedFileInfo = JSON.stringify(fileInfo);
        const params = new HttpParams().set('fileInfo', encodedFileInfo);
        return this.http.get<any>(this.buildStorageUrl(), { params });
    }
}