// src/app/core/core.module.ts
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlantService } from '../features/plants/application/plant.service';
import { PlantRepository } from '../infrastructure/repositories/plant-repository';
import { IPlantService } from '../features/plants/application/plant.service.interface';
import { IPlantRepository } from '../domain/repositories/plant-repository.interface';
import { IProjectRepository } from '../domain/repositories/project-repository.interface';
import { ProjectRepository } from '../infrastructure/repositories/project-repository';
import { IJointRepository } from '../domain/repositories/joint-respository.interface';
import { JointRepository } from '../infrastructure/repositories/joint-repository';
import { ProjectService } from '../features/projects/application/project.service';
import { IProjectService } from '../features/projects/application/project.service.interface';
import { IJointService } from '../features/joints/application/joint.service.interface';
import { JointService } from '../features/joints/application/joint.service';
import { IImageRepository } from '../domain/repositories/image-repository.interface';
import { ImageRepository } from '../infrastructure/repositories/image-repository';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    { provide: IImageRepository, useClass: ImageRepository },
    { provide: IPlantRepository, useClass: PlantRepository },
    { provide: IProjectRepository, useClass: ProjectRepository },
    { provide: IJointRepository, useClass: JointRepository },
    { provide: IPlantService, useClass: PlantService },
    { provide: IProjectService, useClass: ProjectService },
    { provide: IJointService, useClass: JointService },
  ]
})
export class CoreModule {

}
