import { catchError, map, Observable } from "rxjs";
import { IProjectService } from "./project.service.interface";
import { IProjectRepository } from "src/app/domain/repositories/project-repository.interface";
import { Project } from "src/app/domain/models/project";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { IImageRepository } from "src/app/domain/repositories/image-repository.interface";
import { Joint } from "src/app/domain/models/joint";
import { IJointRepository } from "src/app/domain/repositories/joint-respository.interface";

@Injectable({
    providedIn: 'root'
})
export class ProjectService implements IProjectService {

    constructor(private projectRepository: IProjectRepository,
        private jointRepository: IJointRepository,
        private imageRepository: IImageRepository
    ) { }

    getAllProjects(): Observable<Project[]> {
        return this.projectRepository.getAll();
    }

    getProjectDetail(projectId: string): Observable<Project | undefined> {
        return this.projectRepository.get(projectId).pipe(
            map(projects => {
                const project = projects.find((p: Project) => p.projectId === projectId);
                return project;
            }),
            catchError(error => {
                console.error('Error fetching project details', error);
                throw error;
            })
        );
    }

    getProjectJoints(projectId: string): Observable<Joint[]> {
        return this.jointRepository.getJointsByProjectId(projectId);
    }

    getProjectDrawings(project: Project): Observable<string[]> {
        const fileInfoDrawing = this.createFileInfo(project);
        return this.imageRepository.getImagesOptimized(fileInfoDrawing, 'extra').pipe(
            map(response => response.fileDownloadUrls.filter((url: string) => url.includes('.webp'))),
            catchError(error => {
            console.error('Error fetching plant drawings', error);
            return [];
            })
        );
    }

    deleteProjectDrawing(drawing: string): Observable<boolean> {
        const [baseUrl] = drawing.split('?');
        let fileId = new URL(baseUrl).pathname.replace("_extra", "");
        return this.imageRepository.deleteImage(fileId, 'Drawing');
    }

    private createFileInfo(project: Project): any {
        return {
            fileType: 'Image',
            businessVertical: environment.verticalBusiness,
            customerId: project?.company?.code ?? '',
            entityName: 'plants',
            entityId: project.plant?.code + "_IMAGE"
        };
    }
}