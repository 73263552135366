import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AppLayoutComponent } from './shared/layout/app.layout.component';
import { AngularFireAuthGuard, redirectUnauthorizedTo, redirectLoggedInTo } from '@angular/fire/compat/auth-guard';

const redirectUnauthorizedToSignIn = () => redirectUnauthorizedTo(['login']);
const redirectLoggedInToDashboard = () => redirectLoggedInTo(['dashboard']);

const routerOptions: ExtraOptions = {
    anchorScrolling: 'enabled'
};

const routes: Routes = [
    {
        path: '',
        loadChildren: () =>
            import('src/app/shared/auth/auth.module').then((m) => m.AuthModule),
        canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectLoggedInToDashboard },
    },
    {
        path: '', component: AppLayoutComponent,
        children: [
            {
                path: 'dashboard', loadChildren: () => import('./features/dashboards/dashboards.module').then(m => m.DashboardsModule),
                canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToSignIn },
            },
            {
                path: 'plants', loadChildren: () => import('./features/plants/plants.module').then(m => m.PlantsModule),
                canActivate: [AngularFireAuthGuard], data: { breadcrumb: 'globals.plants', authGuardPipe: redirectUnauthorizedToSignIn },
            },
            {
                path: 'joints', loadChildren: () => import('./features/joints/joints.module').then(m => m.JointsModule),
                canActivate: [AngularFireAuthGuard], data: { breadcrumb: 'globals.joints', authGuardPipe: redirectUnauthorizedToSignIn },
            },
            {
                path: 'projects', loadChildren: () => import('./features/projects/projects.module').then(m => m.ProjectsModule),
                canActivate: [AngularFireAuthGuard], data: { breadcrumb: 'globals.projects', authGuardPipe: redirectUnauthorizedToSignIn },
            },
        ]
    },
    { path: 'notfound', loadChildren: () => import('./shared/notfound/notfound.module').then(m => m.NotfoundModule) },
    {
        path: '**',
        redirectTo: '',
        pathMatch: 'full',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, routerOptions)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
